/*
 * Script which shows/ closes the news popup and creates the viewed news cookie
 *
 * @author Robin van Leeuwen <robin@click.nl>
 * @credits Joost Ligthart <joost@click.nl>, Lennart van Welzen <lennart@click.nl>, Rianne Oosthoek <rianne@click.nl>
 */
window.news_popup = function() {
    var self = this;
    var $button_close_news_popup        = document.querySelector('.js-button-close-news-popup');
    var $button_redirect_to_article     = document.querySelector('.js-button-redirect-to-article');
    var $news_window                    = document.querySelector('.js-news-popup');
    var $body                           = document.body;
    var is_cookies_set                  = false;

    self.init = function() {
        if($news_window !== null) {
            // Show news popup on page load when cookie are not set yet
            if( self.getCookie('viewed_news') === '' ) {
                $news_window.classList.remove('hide');
                $body.classList.add('disable-scroll');
            }
        }

        // Set the is_cookies_set to true when the cookie is set. This will be used in future checks
        if( self.getCookie('viewed_news') !== '' ) {
            is_cookies_set = true;
        }

        // When the user closes the news popup, create the viewed news cookie
        $button_close_news_popup.addEventListener('click', self.closeNewsPopup);

        // When the user wants to read the article, close the popup, set the viewed news cookie and redirect to article
        $button_redirect_to_article.addEventListener('click', self.redirectToArticle);
    };

    /**
     * Create viewed news cookie when user closes news popup
     *
     * @author Robin van Leeuwen <robin@click.nl>
     * @param  event
     * @return void
     */
    self.closeNewsPopup = function(event) {
        event.preventDefault();
        // Create cookie for viewed news
        // Value of cookie must be typeof string because boolean `false` results into an empty value
        self.setCookie('viewed_news', '1', 365, false);
        // Hide the news window if it's in a popup
        if($news_window !== null) {
            $news_window.classList.add('hide');
        }
    };

    /**
     * Redirect to article when the user clicks on the read article button
     *
     * @author Robin van Leeuwen <robin@click.nl>
     * @param  event
     * @return void
     */
    self.redirectToArticle = function(event) {
        event.preventDefault();
        // Create cookie for viewed news
        // Value of cookie must be typeof string because boolean `false` results into an empty value
        self.setCookie('viewed_news', '1', 365, false);
        // Hide the news window if it's in a popup
        if($news_window !== null) {
            $news_window.classList.add('hide');
        }

        window.open($button_redirect_to_article.href, '_blank');
    };

    /**
     * Set cookie in client browser
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @edited by Robin van Leeuwen <robin@click.nl>
     * @param   {string}    name  Name of the cookie
     * @param   {boolean}   value  Value of the cookie
     * @param   {int}       days  Amount of days wherein the cookie expires
     * @param   {boolean}   is_reload_page Reload page when value is true
     */
    self.setCookie = function setCookie( name, value, days, is_reload_page ) {
        var expires = '';
        // If days are given, set expire date
        if( days ){
            // Create date instance
            var date = new Date();
            // Turn days into milliseconds
            date.setTime( date.getTime() + ( days*24*60*60*1000 ) );
            // Create expire date
            expires = '; expires=' + date.toUTCString();
        }

        // Set cookie
        document.cookie = name + '=' + ( value || '' )  + expires + '; path=/';

        // Reload the page so the PHP checks will work in the blade files
        if(is_reload_page) {
            location.reload();
        } else {
            $body.classList.remove('disable-scroll');
        }
    };

    /**
     * Get cookie name
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @edited by Joost Ligthart <joost@click.nl>
     * @param   {string}  cookie_name   Name of the cookie we want to check for existence
     * @return  {string}  cookie value or empty string
     */
    self.getCookie = function( cookie_name ){
        var name = cookie_name + '=';
        var cookie_arr = document.cookie.split(';');

        // Loop through all cookies
        for( var i = 0; i < cookie_arr.length; i++ ) {
            var cookie = cookie_arr[i];
            // When first character is a space, get the substring after the space
            while( cookie.charAt(0) == ' ' ){
                cookie = cookie.substring(1);
            }
            // Check if cookie name exists in array of cookies
            if( cookie.indexOf(name) === 0 ){
                return cookie.substring( name.length, cookie.length );
            }
        }

        // Return empty string when cookie doesn't exists
        return '';
    };
};
